import axios from "axios";  

// const baseURL = process.env.REACT_APP_BASE_URL;
// const baseURL = "http://localhost:8000/api/";
// const baseURL = "https://backend.stell.one/api/";

  const currentDomain = window.location.hostname;
  var baseURL = '';
  if (currentDomain === 'dev.stell.one') {
     baseURL = "https://dev-backend.stell.one/api/";
  } 
  else if (currentDomain === 'stell.one') {
    baseURL = "https://prod-backend.stell.one/api/";
  }
  else if(currentDomain === 'staging.stell.one'){
    baseURL = "https://backend.stell.one/api/";
  }
   else if (currentDomain === 'localhost' || currentDomain === '127.0.0.1' || currentDomain === '0.0.0.0') {
     baseURL = "http://localhost:8000/api/";
    //  baseURL = "https://backend.stell.one/api/";
    // baseURL = "https://prod-backend.stell.one/api/";
  }else{
    baseURL = "https://backend.stell.one/api/";
  }

const axiosAPI = axios.create({
  baseURL: baseURL,
  // timeout: 5000,
});

const axiosPublic = axios.create({
  baseURL: baseURL,
});

const requestHandler = (request) => {
  if(request.hasOwnProperty("Content-Type")){
    console.log("request content_type exist already  >>>>>>",request)
  }else{
    // console.log("request<<<<<",request)
     request.headers['Content-Type'] = "application/json";
  }
  // const accessToken = JSON.parse(sessionStorage.getItem("access"));
    const accessToken = JSON.parse(localStorage.getItem("access"));
    request.headers['Authorization'] = "Bearer " + accessToken;
    request.headers['accept'] = "application/json";
    const user = JSON.parse(localStorage.getItem("authUser"));
    // const user = JSON.parse(sessionStorage.getItem("authUser"));
    if (user) {
      request.user = user['username'];
    }
    return request
};


axiosAPI.interceptors.request.use(
  request => requestHandler(request),
  (error) => {
    if (error.response.status >= 500) {
      console.log(error);
    }
    return Promise.reject(error);
  },
);  


axiosAPI.interceptors.response.use((response) => response,

  async (error) => {
    console.log('error error 69',error)
    const originalRequest = error.config;
    // Prevent infinite loops
    if (
      error.response.status === 401 &&
      originalRequest.url === baseURL + "auth/token/refresh/"
    ) {
      window.location.href = "/signin";
      return Promise.reject(error);
    }
    if (
      error.response.status === 401
      //  &&
      // error.response.statusText === "Unauthorized" // this is commented out because it is not always the case on production (it is not allowing to call the refresh token again on 401)
    ) {
      // const refresh = JSON.parse(sessionStorage.getItem("refresh"));
      const refresh = JSON.parse(localStorage.getItem("refresh"));
      // console.log('refresh 86',refresh)
      if (refresh && refresh !== 'undefined') {
        const tokenParts = JSON.parse(atob(refresh.split(".")[1]));
        // console.log('tokenParts 88',tokenParts)
        // exp date in token is expressed in seconds, while now() returns milliseconds:
        const now = Math.ceil(Date.now() / 1000);
        // console.log('now 91',now)
        // console.log('tokenParts.exp > now 91',tokenParts?.exp > now)
        if (tokenParts.exp > now) {
          try {
            // console.log('enter in try block 95')
            const response = await axiosAPI.post(baseURL + "auth/token/refresh/", {
              refresh,
            });
            setNewHeaders(response);
            originalRequest.headers["Authorization"] =
              "Bearer " + response.data.access;
            return axiosAPI(originalRequest);
          } catch (error) {
            sessionStorage.clear();
            localStorage.clear();
            // console.log("Refresh token is expired 105", tokenParts.exp, now);
            window.location.href = "/signin";
          }
        } else {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = "/signin";
        }
      } else {
        console.log("Refresh token not available.");
        window.location.href = "/signin";
        //return Promise.reject(error);
      }
    }

    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export function setNewHeaders(response) {
  axiosAPI.defaults.headers["Authorization"] = "Bearer " + response.data.access;
  localStorage.setItem("access", JSON.stringify(response.data.access));
  // sessionStorage.setItem("access", JSON.stringify(response.data.access));
  if (response.data.refresh) {
    localStorage.setItem("refresh", JSON.stringify(response.data.refresh));
      // sessionStorage.setItem("refresh", JSON.stringify(response.data.refresh));
  }
  else {
      const refreshToken = localStorage.getItem("refresh");
      localStorage.setItem("refresh", refreshToken);
      // const refreshToken = sessionStorage.getItem("refresh");
      // sessionStorage.setItem("refresh", refreshToken);

  }
}

const publicRequestHandler = (request) => {
  if (!request.hasOwnProperty("Content-Type")) {
    request.headers["Content-Type"] = "application/json";
  }
  return request;
};
axiosPublic.interceptors.request.use(
  (request) => publicRequestHandler(request),
  (error) => {
    if (error.response.status >= 500) {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export { axiosPublic, axiosAPI }
// export default axiosAPI;
// export { axiosAPI, axiosPublic };
