import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal, Radio, Tabs, Table, Pagination, AutoComplete, Input, Form, Select, Tooltip } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { userService } from '../../../appRedux/services';
import {
  GET_TRANSFER_REQUEST_FOR_ME,
  GET_TRANSFER_REQUEST_BY_ME,
  UPDATE_TRANSFER_REQUEST
} from '../../../constants/ApiEndpoints';
import { fetchError, showMessage } from "../../../appRedux/actions/Common";
import { useDispatch } from "react-redux";
import CenteredModal from '../../Extras/CenteredModal';
import moment from 'moment';
import { displayDateInMMDDYYYYTime } from '../../../routes/recruitment/screening/utils';
import { Capitalize } from '../../../routes/recruitment/screening/utils';
import { useSelector } from 'react-redux';
import { fetchAllRecruiter, fetchScreeners } from '../../../appRedux/actions';
import { defaultPageListLimit } from '../../../constants/Constants';
import { DynamicPageListLength } from './DynamicPagination/DynamicPagianation';
import { StyledClientTable } from './StyledTableComponent/StyledConsultantFixedheaderTable';

const TransferRequest = ({ user }) => {
  const dispatch = useDispatch();
  const isAdmin = user ? user.groups ? user.groups.includes("Admin") : false : false;
  const DataContainer = styled.div`
  // padding:0 !important;
  // background-color: #038fdd

`;
  const TabPane = Tabs.TabPane;
  let forMeCols = [];
  let byMeCols = [];
  const { screeners, allRecruiter } = useSelector(({ employees }) => employees);
  const [transferRequestByMe, setTransferRequestByMe] = React.useState(null);
  const [transferRequestForMe, setTransferRequestForMe] = React.useState(null);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [objId, setObjId] = React.useState(null);
  const innitialPageNo = Number(sessionStorage.getItem('transferRequestPageNo')) || 1;
  const [currentPageForMe, setCurrentPageForMe] = React.useState(innitialPageNo);
  const [currentPageByMe, setCurrentPageByMe] = React.useState(1);
  const [selectedRecruiterId, setSelectedRecruiterId] = useState(null);
  const [consultantSearchInputValue, setConsultantSearchInputValue] = useState(null);
  const [openTab, setOpenTab] = useState('requestsForMe');
  const Option = Select;
  const [form] = Form.useForm();
  const timeoutRef = useRef(null);

  const initialPageSize = Number(sessionStorage.getItem('pageSize')) || defaultPageListLimit;
  const [pageSize, setPageSize] = useState(initialPageSize)

  useEffect(() => {
    setPageSize(initialPageSize)
  }, [initialPageSize])

  useEffect(() => {
    setCurrentPageForMe(innitialPageNo)
  }, [innitialPageNo])

  // useEffect(()=>{
  //   if(!screeners && user && user.id){
  //       dispatch(fetchScreeners(user.id))
  //   }   
  // },[user])

  useEffect(() => {
    if (!allRecruiter) {
      dispatch(fetchAllRecruiter(user.id))
    }
  }, [])

  const loadTransferRequestForMe = (page = 1, filters = { recruiterId: null, q: null, page_Size: null }) => {
    let endPoint = `${GET_TRANSFER_REQUEST_FOR_ME}?page=${page}`;
    if (filters.recruiterId !== null && filters.recruiterId !== undefined) {
      endPoint += `&recruiter=${filters.recruiterId}`;
    }
    if (filters.q !== null && filters.q !== undefined) {
      endPoint += `&q=${filters.q}`;
    }
    if (filters.page_Size !== null && user && user.groups.includes('Admin')) {
      endPoint += `&page_size=${filters.page_Size}`
    }

    userService
      .get(endPoint)
      .then((response) => {
        setTransferRequestForMe(response);
      }).catch((response) => {
        dispatch(fetchError(response.data.error + "!"));
      })
  }
  const loadTransferRequestByMe = (page = 1, filters = { recruiterId: null, q: null }) => {
    let endPoint = GET_TRANSFER_REQUEST_BY_ME + `?page=${page}`
    if (filters.recruiterId !== null) {
      endPoint += `&recruiter=${filters.recruiterId}`;
    }
    if (filters.q !== null) {
      endPoint += `&q=${filters.q}`;
    }
    userService
      .get(endPoint)
      .then((response) => {
        setTransferRequestByMe(response)
      }).catch((response) => {
        dispatch(fetchError(response.data.error + "!"));
      })
  }
  useEffect(() => {
    loadTransferRequestForMe(currentPageForMe, { page_Size: pageSize })
    if (!isAdmin) {
      loadTransferRequestByMe()
    }

  }, [])

  const onChangeByMe = (page) => {
    setCurrentPageByMe(page);
    loadTransferRequestByMe(page, { recruiterId: selectedRecruiterId, q: consultantSearchInputValue })
  }
  const onChangeForMe = (page) => {
    sessionStorage.setItem('transferRequestPageNo', page)
    setCurrentPageForMe(page);
    loadTransferRequestForMe(page, { page_Size: pageSize, recruiterId: selectedRecruiterId, q: consultantSearchInputValue })
  }

  const handleApproveClick = (id) => {
    setShowApproveModal(true)
    setObjId(id)
  }

  const handleRejectClick = (id) => {
    setShowRejectModal(true)
    setObjId(id)
  }
  const handleApproveResponse = (response) => {
    if (response) {
      let body = {
        request_id: objId,
        status: 'a'
      }
      userService
        .post(UPDATE_TRANSFER_REQUEST, body)
        .then((response) => {
          loadTransferRequestForMe(currentPageForMe, { page_Size: pageSize })
        }).catch((response) => {
          dispatch(fetchError(response.data.error + "!"));
        })
    }
    setObjId(null)
    setShowApproveModal(false)
  }
  const handleRejectResponse = (response) => {
    if (response) {
      let body = {
        request_id: objId,
        status: 'r'
      }
      userService
        .post(UPDATE_TRANSFER_REQUEST, body)
        .then((response) => {
          loadTransferRequestForMe(currentPageForMe, { page_Size: pageSize })
        }).catch((response) => {
          dispatch(fetchError(response.data.error + "!"));
        })
    }
    setObjId(null)
    setShowRejectModal(false)
  }

  forMeCols = [
    {
      title: 'Consultant',
      align: 'center',
      dataIndex: 'consultant_name',
      key: 'consultant_name',
      render: consultant_name => {
        return <DataContainer className="gx-badge" style={{ margin: "8px" }} key={consultant_name}>
          {Capitalize(consultant_name)}
        </DataContainer>
      }
    },
    {
      title: 'Consultant Email',
      align: 'center',
      dataIndex: 'consultant_email',
      key: 'consultant_email',
      render: consultant_email => {
        return <DataContainer className="gx-badge" key={consultant_email}>
          {consultant_email}
        </DataContainer>
      }
    },
    {
      title: 'Requested By',
      align: 'center',
      dataIndex: 'requested_by_name',
      key: 'requested_by_name',
      render: requested_by_name => {
        return <DataContainer className="gx-badge" key={requested_by_name}>
          {Capitalize(requested_by_name)}
        </DataContainer>
      }
    },
    {
      title: 'Requested At',
      align: 'center',
      dataIndex: 'created_at',
      key: 'created_at',
      render: created_at => {
        return <DataContainer className="gx-badge" key={created_at}>
          {created_at ? displayDateInMMDDYYYYTime(created_at) : "-"}
        </DataContainer>
      }
    },
    {
      title: 'Current Recruiter',
      align: 'center',
      dataIndex: 'recruiter_name',
      key: 'recruiter_name',
      render: recruiter_name => {
        return <DataContainer className="gx-badge" key={recruiter_name}>
          {Capitalize(recruiter_name)}
        </DataContainer>
      }
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status_display',
      key: 'status_display',
      render: status_display => {
        let statusColor = '';
        
        if (status_display.toLowerCase() === 'approved') {
          statusColor = '#52c41a'; 
        } else if (status_display.toLowerCase() === 'pending') {
          statusColor = '#1677ff';  
        } else if (status_display.toLowerCase() === 'rejected') {
          statusColor = '#ff4d4f';  
        } else {
          statusColor = '#000000';  // default black
        }

        return (
          <DataContainer 
            className="gx-badge" 
            key={status_display}
            style={{ color: statusColor }}
          >
            {status_display}
          </DataContainer>
        );
      }
    },
    {
      title: 'Last Action By',
      align: 'center',
      dataIndex: 'action_by_name',
      key: 'action_by_name',
      render: action_by_name => {
        return <DataContainer className="gx-badge" key={action_by_name}>
          {action_by_name ? Capitalize(action_by_name) : 'NA'}
        </DataContainer>
      }
    },
    {
      title: 'Last Action Date',
      align: 'center',
      dataIndex: 'modified',
      key: 'modified',
      render: modified => {
        return <DataContainer className="gx-badge" key={modified}>
          {modified ? displayDateInMMDDYYYYTime(modified) : '-'}
        </DataContainer>
      }
    },
    {
      title: 'Action',
      align: 'center',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => {
        return <div style={{ padding: "5px" }}>
          {(record.status === 'p') && <div style={{ display: 'flex', paddingBottom: 0, marginBottom: "-11px" }}>
            <Button
              className="ant-btn ant-btn-primary"
              key={id}
              icon={<CheckCircleOutlined />}
              title='Approve'
              onClick={() => handleApproveClick(id)}>
            </Button>

            <Button
              className="ant-btn ant-btn-danger"
              key={id}
              icon={<CloseCircleOutlined />}
              title='Reject'
              onClick={() => handleRejectClick(id)}>
            </Button></div>}
        </div>
      }
    }
  ];
  byMeCols = [
    {
      title: 'Consultant',
      align: 'center',
      dataIndex: 'consultant_name',
      key: 'consultant_name',
      sorter: { multiple: 1 },
      render: consultant_name => {
        return <DataContainer className="gx-badge" key={consultant_name}>
          {consultant_name}
        </DataContainer>
      }
    },
    {
      title: 'Consultant Email',
      align: 'center',
      dataIndex: 'consultant_email',
      key: 'consultant_email',
      render: consultant_email => {
        return <DataContainer className="gx-badge" key={consultant_email}>
          {consultant_email}
        </DataContainer>
      }
    },
    {
      title: 'Current Recruiter',
      align: 'center',
      dataIndex: 'recruiter_name',
      key: 'recruiter_name',
      sorter: { multiple: 1 },
      render: recruiter_name => {
        return <DataContainer className="gx-badge" key={recruiter_name}>
          {recruiter_name}
        </DataContainer>
      }
    }, ,
    {
      title: 'Manager',
      align: 'center',
      dataIndex: 'manager_name',
      key: 'manager_name',
      sorter: { multiple: 1 },
      render: manager_name => {
        return <DataContainer className="gx-badge" key={manager_name}>
          {manager_name}
        </DataContainer>
      }
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status_display',
      key: 'status_display',
      sorter: { multiple: 1 },
      render: status_display => {
        let statusColor = '';
        
        if (status_display.toLowerCase() === 'approved') {
          statusColor = '#52c41a';  // green
        } else if (status_display.toLowerCase() === 'pending') {
          statusColor = '#ff69b4';  // pink
        } else if (status_display.toLowerCase() === 'rejected') {
          statusColor = '#ff4d4f';  // red
        } else {
          statusColor = '#000000';  // default black
        }

        return (
          <DataContainer 
            className="gx-badge" 
            key={status_display}
            style={{ color: statusColor }}
          >
            {status_display}
          </DataContainer>
        );
      }
    },
    {
      title: 'Last Action By',
      align: 'center',
      dataIndex: 'action_by_name',
      key: 'action_by_name',
      sorter: { multiple: 1 },
      render: action_by_name => {
        return <DataContainer className="gx-badge" key={action_by_name}>
          {action_by_name}
        </DataContainer>
      }
    },
    {
      title: 'Last Action Date',
      align: 'center',
      dataIndex: 'modified',
      key: 'modified',
      sorter: { multiple: 1 },
      render: modified => {
        return <DataContainer className="gx-badge" key={modified}>
          {modified ? displayDateInMMDDYYYYTime(modified) : '-'}
        </DataContainer>
      }
    },
  ];

  const [selctedRecruiters, setSelctedRecruiters] = useState([])
  const handleRecruiterSelect = (value) => {
    if (value) {
      setSelctedRecruiters(value)
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        onSubmit({ recruiter_id: value });
      }, 500);
    }
  }

  const handleDispatch = (filterKey = { openTab: null, recruiter_id: null, consultantSearchInputValue: null }) => {
    let functionToDispatch;
    if (filterKey.openTab === 'requestsByMe') {
      functionToDispatch = loadTransferRequestByMe;
    } else if (filterKey.openTab === 'requestsForMe') {
      functionToDispatch = loadTransferRequestForMe;
    }
    if (functionToDispatch) {
      const params = {
        recruiterId: filterKey.recruiter_id ? filterKey.recruiter_id : null,
        q: filterKey.consultantSearchInputValue ? filterKey.consultantSearchInputValue : null
      };
      if (functionToDispatch === loadTransferRequestForMe) {
        params.page_Size = pageSize;
      }
      functionToDispatch(1, params);
      // functionToDispatch(1, { recruiterId: filterKey.recruiter_id, q: filterKey.consultantSearchInputValue ,page_Size: pageSize});
    }
  };

  const onSubmit = (value) => {
    setCurrentPageForMe(1)
    setCurrentPageByMe(1)
    if (value && value.recruiter_id) {
      setSelectedRecruiterId(value.recruiter_id)
      sessionStorage.removeItem('transferRequestPageNo')
      handleDispatch({ openTab: openTab, recruiter_id: value.recruiter_id, consultantSearchInputValue: consultantSearchInputValue });
    }
  }

  const handleRemoveAll = (value) => {
    form.resetFields();
    setCurrentPageForMe(1)
    setCurrentPageByMe(1)
    setSelctedRecruiters([])
    setSelectedRecruiterId(null)
    sessionStorage.removeItem('transferRequestPageNo')
    handleDispatch({ openTab: openTab, recruiter_id: null, consultantSearchInputValue: consultantSearchInputValue });
  }
  const initialRender = useRef(true)
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      const debounceTimer = setTimeout(() => {
        setCurrentPageForMe(1)
        setCurrentPageByMe(1)
        handleDispatch({ openTab: openTab, recruiter_id: selectedRecruiterId, consultantSearchInputValue: consultantSearchInputValue });
      }, 400);
      return () => clearTimeout(debounceTimer)
    }
  }, [consultantSearchInputValue])

  const callback = (key) => {
    if (key === "requestsByMe") {
      // form.resetFields();
      // setSelctedRecruiters([])  // delete the selected filters on the tab change
      setOpenTab('requestsForMe')
    } else if (key === "requestsForMe") {
      setOpenTab('requestsByMe')
    }
    else {
      console.log("No such key.")
    }
  };
  const handlePageSizeChange = (value) => {

    const maxPageLength = transferRequestForMe && transferRequestForMe.count ? Math.ceil(transferRequestForMe.count / value) : 1
    const redirectToPageNumber = currentPageForMe >= maxPageLength ? maxPageLength : currentPageForMe
    if (value) {
      sessionStorage.setItem('pageSize', value);
      setPageSize(value)
      setCurrentPageForMe(redirectToPageNumber)
      sessionStorage.setItem('transferRequestPageNo', JSON.stringify(redirectToPageNumber));
      callApiOnPageSizeChange(value, redirectToPageNumber)
    }
  }

  const callApiOnPageSizeChange = (value, redirectToPageNumber) => {
    loadTransferRequestForMe(redirectToPageNumber, { page_Size: value, recruiterId: selectedRecruiterId, q: consultantSearchInputValue })
  }

  const handleOnSearch = (value) => {
    if (value && value.length >= 3) {
      if (initialRender.current) {
        initialRender.current = false;
      } else {
        const debounceTimer = setTimeout(() => {
          setCurrentPageForMe(1)
          setCurrentPageByMe(1)
          handleDispatch({ openTab: openTab, recruiter_id: selectedRecruiterId, consultantSearchInputValue: value });
        }, 400);
        return () => clearTimeout(debounceTimer)
      }
    }
  }

  return (
    <div className='p-4'>
      <div className="gx-module-box-content p-4" >
        <Form form={form} style={{ height: "40px", display: "flex", gap: 30 }} onFinish={onSubmit} >
          <AutoComplete
            popupClassName="certain-category-search-dropdown"
            dropdownMatchSelectWidth={700}
            style={{
              width: 250,
            }}
          >
            <Input.Search
              placeholder="Search by consultant name"
              onSearch={(value) => handleOnSearch(value)}
              onChange={(event) => {
                event.target.value.length >= 3 && setConsultantSearchInputValue(event.target.value.trim());
                event.target.value.length === 0 && setConsultantSearchInputValue(null);
              }}
              enterButton />
          </AutoComplete>

          {allRecruiter && <Form.Item name="recruiter_id" >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              maxTagCount={2}
              style={{ minWidth: "200px", maxWidth: "500px" }}
              mode="multiple"
              placeholder="Select recruiter"
              dropdownMatchSelectWidth={435}
              onChange={handleRecruiterSelect}
            >
              {allRecruiter && allRecruiter.map((item) => <Option value={item.user_id} key={item.user_id} >{item.name}</Option>)}
            </Select>
          </Form.Item>}
          {selctedRecruiters && selctedRecruiters.length > 0 ? <>
            <Button type="primary" htmlType='submit'>Apply</Button>
            <Tooltip title="Remove filter">
              <img src={require("assets/images/delete image.png")} alt="Remove filter" onClick={handleRemoveAll} style={{ width: "30px", height: "30px", cursor: "pointer" }} />
            </Tooltip>
          </>
            : null}
        </Form>

        <Tabs defaultActiveKey={"requestsByMe"} onChange={callback} centered>
          <TabPane tab={!isAdmin ? <span>{"For Me"}</span> : <span>{"All Transfer Requests"}</span>}
            key="requestsByMe">
            {transferRequestForMe ?
              <div className="gx-module-box-content">
                <div className="gx-mb-3" style={{ display: "flex", marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                  <StyledClientTable
                    className="gx-table-responsive transfer-request-tbl"
                    styleName="gx-card-list"
                    style={{ width: '100%' }}
                    bordered
                    tableLayout={"auto"}
                    onChange={() => console.log("Table Changed")}
                    columns={forMeCols}
                    dataSource={transferRequestForMe.results}
                    pagination={false}
                    rowKey={'id'}
                  />
                  {/* <RadioGroup onChange={(e) => fetchScreeningsByFilter(e.target.value, 'forMe')}
                    defaultValue={defaultValue}>
          <RadioButton value="past">Past</RadioButton>
          <RadioButton value="scheduled">Scheduled</RadioButton>
        </RadioGroup> */}
                </div>
                <div className="gx-mb-3" style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>

                  {transferRequestForMe && transferRequestForMe.count && transferRequestForMe && transferRequestForMe.results !== null ?
                    <>
                      <Pagination simple size="small" total={transferRequestForMe.count} onChange={onChangeForMe} showQuickJumper={false}
                        showSizeChanger={false} hideOnSinglePage={true} current={currentPageForMe} defaultCurrent={currentPageForMe}
                        pageSize={pageSize} showTotal={(total, range) => `${range[1]} of ${total} items`}
                      />

                      {user && user.groups.includes("Admin") ?
                        <DynamicPageListLength
                          page_Size={pageSize}
                          handlePageSizeChange={handlePageSizeChange}
                        />
                        : null
                      }
                    </> : null}
                </div>
              </div>
              : null}
          </TabPane>
          {!isAdmin && <TabPane tab={
            <span>
              {/*<EyeOutlined/>*/}
              {"By Me"}
            </span>
          } key="requestsForMe">
            {transferRequestByMe ?
              <div className="gx-module-box-content">
                <div className="gx-mb-3" style={{ display: "flex", marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                  <StyledClientTable
                    className="gx-table-responsive  transfer-request-tbl"
                    styleName="gx-card-list"
                    style={{ width: '100%' }}
                    bordered
                    tableLayout={"auto"}
                    onChange={() => console.log("Table Changed")}
                    columns={byMeCols}
                    dataSource={transferRequestByMe.results}
                    pagination={false}
                    rowKey={'id'}
                  />
                  {/* <RadioGroup onChange={(e) => fetchScreeningsByFilter(e.target.value, 'forMe')}
                    defaultValue={defaultValue}>
          <RadioButton value="past">Past</RadioButton>
          <RadioButton value="scheduled">Scheduled</RadioButton>
        </RadioGroup> */}
                </div>
                <div className="gx-mb-3" style={{ display: "flex", justifyContent: "center", marginTop: '10px' }}>
                  <Pagination simple size="small" total={transferRequestByMe.count} onChange={onChangeByMe} showQuickJumper={false}
                    showSizeChanger={false} hideOnSinglePage={true} current={currentPageByMe} defaultCurrent={currentPageByMe} />
                </div>
              </div>
              : null}
          </TabPane>}
        </Tabs>
      </div>
      <CenteredModal
        title="Confirm Reject"
        isVisible={showRejectModal}
        onUserResponse={handleRejectResponse}
        okText="Yes"
        cancelText="Cancel"
        message={"Are you sure you want to Reject this request ?"}
      >
      </CenteredModal>
      <CenteredModal
        title="Confirm Approve"
        isVisible={showApproveModal}
        onUserResponse={handleApproveResponse}
        okText="Yes"
        cancelText="Cancel"
        message='Are you sure you want to Approve this request ?'
      >
      </CenteredModal>

    </div>
  )
}

export default TransferRequest

