import React from "react";
import ReactDOM from "react-dom/client"; // Note the `client` import for createRoot

import NextApp from './NextApp';
import registerServiceWorker from './registerServiceWorker';
import './styles/common/common.css';

// Get the root element from the DOM
const rootElement = document.getElementById('root');

// Create a root and render the component
const root = ReactDOM.createRoot(rootElement);
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    registrations.forEach(registration => registration.unregister());
  });
}

root.render(
  // <React.StrictMode>
    <NextApp />
  // </React.StrictMode>
);

// Register service worker
registerServiceWorker();

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    root.render(<NextApp />);
  });
}


// import React from "react";
// import ReactDOM from "react-dom";

// import NextApp from './NextApp';
// import registerServiceWorker from './registerServiceWorker';
// // Add this import:
// // import {AppContainer} from 'react-hot-loader';
// const rootElement = document.getElementById('root');
// const root = ReactDOM.createRoot(rootElement);
// // Wrap the rendering in a function:
// const render = Component => {
//   root.render(
//       <Component />
//     );
//   // ReactDOM.render(
//   //   // Wrap App inside AppContainer
//   //   // <AppContainer>
//   //     <Component/>,
//   //   // </AppContainer>,
//   //   document.getElementById('root')
//   // );
// };

// // Do this once
// registerServiceWorker();

// // Render once
// render(NextApp);

// // Webpack Hot Module Replacement API
// if (module.hot) {
//   module.hot.accept('./NextApp', () => {
//     render(NextApp);
//   });
// }
