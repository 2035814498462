
import {fetchError, fetchStart,handleErrorResponse, fetchSuccess, showMessage, handleRemoveDisableSubmitButton} from "./Common";
import {userService} from "../services";
import { ADD_SUPPORT_PERSON, ADD_TIMESHEET_ATTACHMENT, ADD_TIMESHEET_HOURS, APPROVE_CONTRACT_TEAM_ATTACHMENT, ASSIGN_CONSULTANT_PAYEE_ON_OFFERS, ASSIGN_PAYEE_ON_OFFERS, CONTRACT_TEAM_ATTACHMENT, CREATE_OFFER, CREATE_TIMESHEET_CONFIG, DELETE_OFFER, DELETE_SUPPORT_INVOICE, EDIT_OFFERS, EDIT_PROJECT_COORDINATORS_OFFERS, EDIT_PROJECT_COORDINATORS_OFFERS_SUPPORT, EDIT_SUPPORT_INVOICE, EDIT_SUPPORT_INVOICE_TRANSACTION, EDIT_SUPPORT_PERSON, EDIT_TIMESHEET_ATTACHMENT, EDIT_TIMESHEET_HOURS, Edit_CONSULTANT_PAYEE_ON_OFFERS, Edit_CONTRACT_TEAM_OFFERS, Edit_ENGINEER_OFFERS, GET_CONSULTANT_OFFERS, GET_CONTRACT_TEAM_DOCUMENT_LIST, GET_CONTRACT_TEAM_OFFERS, GET_CURRENT_YEAR_CLOSED_OFFER, GET_ENGINEERS_OFFERS, GET_ENGINEER_OFFERS, GET_MONTHWICE_OFFERS, GET_OFFERS, GET_PROJECT_COORDINATORS_LIST, GET_PROJECT_COORDINATORS_OFFERS, GET_SUPPORT_DETAILS_DATA, GET_SUPPORT_INVOICE_ATTACHMENT_TO_APPROVE, GET_SUPPORT_INVOICE_DETAILS_LIST, GET_SUPPORT_INVOICE_LIST, GET_SUPPORT_INVOICE_TRANSACTION, GET_SUPPORT_PERSON_LIST, GET_SUPPORT_PERSON_OPTION_LIST, GET_TEAM_OFFERS, GET_TIMESHEET_ATTACHMENT_LIST, GET_TIMESHEET_CONFIG_LIST, GET_TIMESHEET_HOURS_LIST, SEND_APPROVE_INVOICE_ATTACHMENT, SEND_APPROVE_SUPPORT_INVOICE_ATTACHMENT, SEND_TIMESHEET_ATTACHMENT_REMINDER, UPDATE_INVOICE_ISPAID_STATUS, UPDATE_TIMESHEET_ATTACHMENT_STATUS, UPLOAD_INVOICE_ATTACHMENT } from "../../constants/ApiEndpoints";
import { ADD_OFFER_FAILED, ADD_OFFER_SUCCESS, DELETE_OFFER_FAILURE, DELETE_OFFER_SUCCESS, EDIT_OFFER_FAILED, EDIT_OFFER_SUCCESS, FETCH_CONSULTANT_OFFER_LIST_SUCCESS, FETCH_CONTRACT_INCOMPLETE_VERIFICATION_LIST_SUCCESS, FETCH_CONTRACT_TEAM_DOCUMENT_LIST_SUCCESS, FETCH_CONTRACT_TEAM_OFFER_LIST_SUCCESS, FETCH_CONTRACT_VERIFIED_LIST_SUCCESS, FETCH_CURRENT_YEAR_CLOSED_OFFER_LIST_SUCCESS, FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS, FETCH_ENGINEER_OFFER_FAILED, FETCH_ENGINEER_OFFER_LIST_SUCCESS, FETCH_GET_SUPPORT_DETAILS_DATA, FETCH_GET_SUPPORT_INVOICE_TRANSACTION, FETCH_MONTHLY_OFFER_SUCCESS, FETCH_OFFER_FAILED, FETCH_OFFER_SUCCESS, FETCH_PAY_STUB_TIMESHEET_ATTACHMENT_LIST_SUCCESS, FETCH_PENDING_TIMESHEET_ATTACHMENT_LIST_SUCCESS, FETCH_PROJECT_COORDINATORS_LIST_SUCCESS, FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS, FETCH_PROJECT_COORDINATORS_OFFER_FAILED, FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS, FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS, FETCH_SINGLE_ENGINEER_OFFER_SUCCESS, FETCH_SUPPORT_INVOICE_DETAILS_LIST_SUCCESS, FETCH_SUPPORT_INVOICE_LIST_SUCCESS, FETCH_SUPPORT_PERSON_LIST_SUCCESS, FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS, FETCH_SUPPORT_PERSON_OPTION_LIST_SUCCESS, FETCH_TEAM_OFFER_FAILED, FETCH_TEAM_OFFER_SUCCESS, FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS, FETCH_TIMESHEET_CONFIG_LIST_SUCCESS, FETCH_TIMESHEET_HOURS_LIST_SUCCESS, FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS, REMOVE_PENDING_TIMESHEET_ATTACHMENT_OBJ__FROM_LIST } from "../../constants/ActionTypes";
import { handleErrorsInDifferentKey } from "../../util/ErrorFormating/errorUtils";
import { defaultPageListLimit } from "../../constants/Constants";
import { message } from "antd";

export const addOfferSuccess = (offer) => {
  return {
    type: ADD_OFFER_SUCCESS,
    payload: offer
  }
};

export const addOfferFailed = () => {
  return {
    type: ADD_OFFER_FAILED,
  }
};

export const addOffer = (values) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(CREATE_OFFER, values).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
        dispatch(handleRemoveDisableSubmitButton('add_new_offer'))
        dispatch(addOfferSuccess(response));
        dispatch(fetchOffers());
        dispatch(handleErrorResponse({"addOffer": "success"}));
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_new_offer'))
      dispatch(handleErrorResponse({"addOffer": error}));
      dispatch(fetchError(error?error.data?error.data.error:"Error Adding Offer!":"Error Adding Offer!"));
      dispatch(addOfferFailed());
    })
  }
};


export const fetchOffersSuccess = (offers) => {
  return {
    type: FETCH_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchOffersFailure = () => {
  return {
    type: FETCH_OFFER_FAILED,
  }
};


export const fetchOffers = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null,
    team_profiles:null ,from_date: null ,to_date: null ,include_self:false ,q:null ,page_size:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_OFFERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchOffersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchOffersFailure());
    });
  };
};

// for the marketerDashboard table "offers for the month table"

export const fetchMonthlyOffersSuccess = (offers) => {
  return {
    type: FETCH_MONTHLY_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchMonthlyOffers = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null ,team_profiles:null ,from_date: null ,to_date: null ,include_self:false}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_MONTHWICE_OFFERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchMonthlyOffersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchOffersFailure());
    });
  };
};


// TEAM OFFER
export const fetchTeamOffersSuccess = (offers) => {
  return {
    type: FETCH_TEAM_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchTeamOffersFailure = () => {
  return {
    type: FETCH_TEAM_OFFER_FAILED,
  }
};


export const fetchTeamOffers = (filterBy={page:1 ,q:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_TEAM_OFFERS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchTeamOffersSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTeamOffersFailure());
    });
  };
};

export const deleteOfferSuccess =  () => {
  return{
    type: DELETE_OFFER_SUCCESS,
  }
};

export const deleteOfferFailure =  () => {
  return {
    type: DELETE_OFFER_FAILURE,
  }
};

export const deleteOffer = (offerId ,filterBy={remark : null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = DELETE_OFFER.replace('offerId', offerId);
    userService.patch(endPoint ,filterBy).then((response) => {
        dispatch(deleteOfferSuccess());
        dispatch(fetchOffers());
        dispatch(fetchSuccess());
        dispatch(showMessage("Offer Deleted Successfully!"));
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      if(error && error.data && error.data.errors){
        dispatch(fetchError(error.data.errors));
      }
      dispatch(deleteOfferFailure());
    });
  }
};


export const editOfferSuccess = (offers) => {
  return {
    type: EDIT_OFFER_SUCCESS,
    payload: offers
  }
};

export const editOfferFailure = () => {
  return {
    type: EDIT_OFFER_FAILED,
  }
};

export const editOffer = (offerId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true, currentAppliedFilters:{}}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = EDIT_OFFERS.replace('offerId', offerId);
  userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        dispatch(handleRemoveDisableSubmitButton('edit_offers_form'))
        dispatch(editOfferSuccess(response));
        (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchOffers(filterBy.currentAppliedFilters)) 
        dispatch(showMessage("Offer Edited Successfully"))
        filterBy.updateTableCellOnResponse()
        dispatch(handleErrorResponse({"editOffer": "success"}));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleRemoveDisableSubmitButton('edit_offers_form'))
      handleErrorsInDifferentKey(error)
      dispatch(handleErrorResponse({"editOffer": error}));
      (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchOffers(filterBy.currentAppliedFilters)) 
      dispatch(editOfferFailure());
    });
  };
};

// project coordinator

export const fetchProjectCoordinatorsOffersListSuccess = (offers) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_OFFER_SUCCESS,
    payload: offers
  }
};
export const fetchProjectCoordinatorsOngoingOffersListSuccess = (offers) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_ONGOING_OFFER_SUCCESS,
    payload: offers
  }
};


export const fetchProjectCoordinatorsOffersListFailure = () => {
  return {
    type: FETCH_PROJECT_COORDINATORS_OFFER_FAILED,
  }
};


export const fetchProjectCoordinatorsOffersList = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null,
  team_profiles:null ,from_date: null ,to_date: null  ,q:null ,page_size:null , status:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_PROJECT_COORDINATORS_OFFERS, filterBy).then((response) => {
        if (response) {
          if(filterBy.status==='j'){
            dispatch(fetchProjectCoordinatorsOffersListSuccess(response));
          }
          else{
            dispatch(fetchProjectCoordinatorsOngoingOffersListSuccess(response))
          }
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
        dispatch(fetchProjectCoordinatorsOffersListFailure())
      });
  };
};


export const editProjectCoordinatorsOffers = (offerId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true, 
    status:null ,closeModelOnResponce:()=>{}, currentPageToFetchOnResponce:null}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = EDIT_PROJECT_COORDINATORS_OFFERS.replace('id', offerId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        filterBy.closeModelOnResponce() // this the function to close the model from which he api is called
        // filterBy.updateTableCellOnResponse() // this will be used when editing the table data from the table
        if(filterBy.status==='j'){
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchProjectCoordinatorsOffersList({status:'j', page: filterBy.currentPageToFetchOnResponce}))
          if(values !== null && values.get('project_status') === 'o'){
            dispatch(fetchProjectCoordinatorsOffersList({status:'o', page: filterBy.currentPageToFetchOnResponce}))
          } 
        }
        else{
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchProjectCoordinatorsOffersList({status:'o', page: filterBy.currentPageToFetchOnResponce})) 
        }
        dispatch(handleRemoveDisableSubmitButton('edit_project_coordinators_offers_form'))
        dispatch(showMessage("Offer Edited Successfully"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      // handleErrorsInDifferentKey(error)
      dispatch(handleRemoveDisableSubmitButton('edit_project_coordinators_offers_form'))
    });
  };
};

export const editProjectCoordinatorsOfferSupports = (offerId=null, values=null ,filterBy={ callFunctionOnResponce:(()=>{}) ,formValue:{}, showErrorIfFaild:(()=>{})}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = EDIT_PROJECT_COORDINATORS_OFFERS_SUPPORT.replace('id', offerId);
  userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(filterBy.formValue){
          filterBy.callFunctionOnResponce(filterBy.formValue)
        }
        dispatch(showMessage("Support Updated Successfully!"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      message.error('Failed to update')
      if(error && error.data )
      filterBy.showErrorIfFaild(error.data)
      dispatch(handleRemoveDisableSubmitButton('edit_project_coordinators_offers_form'))
    });
  };
};

export const fetchSupportPersonsListSuccess = (response) => {
  return {
    type: FETCH_SUPPORT_PERSON_LIST_SUCCESS,
    payload: response
  }
};

export const fetchSupportPersonsListWithNoPaginationSuccess = (response) => {
  return {
    type: FETCH_SUPPORT_PERSON_LIST_WITH_NO_PAGINATION_SUCCESS,
    payload: response
  }
};

export const fetchSupportPersonsList = (filterBy={pagination:true,page:1 ,pageSize: defaultPageListLimit, project_coordinator_id:null , name:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_SUPPORT_PERSON_LIST,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          if(filterBy.pagination === false){
            dispatch(fetchSupportPersonsListWithNoPaginationSuccess(response))
          }else{
            dispatch(fetchSupportPersonsListSuccess(response))
          }
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchSupportInvoiceListSucess = (response) => {
  return {
    type: FETCH_SUPPORT_INVOICE_LIST_SUCCESS,
    payload: response
  }
};

export const fetchSupportInvoiceList = (filterBy={page:1, support_id:null, pageSize: defaultPageListLimit, invoice_number: null, start_date: null, end_date: null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_SUPPORT_INVOICE_LIST,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          dispatch(fetchSupportInvoiceListSucess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const resetSupportInvoiceList = () => {
  return(dispatch) => {
    dispatch(fetchSupportInvoiceListSucess({
        "count": 0,
        "results": null
      }
    ))
  };
};

export const fetchSupportPersonsOptionListSuccess = (response) => {
  return {
    type: FETCH_SUPPORT_PERSON_OPTION_LIST_SUCCESS,
    payload: response
  }
};

export const fetchSupportPersonsOptionList = () => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_SUPPORT_PERSON_OPTION_LIST).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          dispatch(fetchSupportPersonsOptionListSuccess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const addSupportPersonSuccess = (offer) => {
  return {
    type: ADD_OFFER_SUCCESS,
    payload: offer
  }
};

export const addSupportPerson = (values, filterBy={ closeModelOnResponse:()=>{} ,pageListToFetchOnResponce:1,
  currentFilters:{}, user:[], pageSize: defaultPageListLimit}) =>{
  return (dispatch) => {
    const filters = filterBy && filterBy.currentFilters
    dispatch(fetchStart());
    userService.post(ADD_SUPPORT_PERSON, values,{
      'Content-Type': 'multipart/form-data',
      }).then((response) => {
      if(response) {
        dispatch(fetchSupportPersonsList({pagination: true ,page: filterBy.pageListToFetchOnResponce, 
          supply_source: filters.supply_source ,name: filters.name ,rate: filters.rate , technology: filters.technology, 
          frequency_of_payment: filters.frequency_of_payment, currency: filters.currency,
          experience: filters.experience 
          ,...( filterBy.user && filterBy.user.groups.includes("Admin") && {page_size: filterBy.pageSize})
        })) 
        dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
        dispatch(showMessage("Added Successfully!"));
        if(typeof filterBy.closeModelOnResponse === 'function'){
          filterBy.closeModelOnResponse();
        }
        dispatch(handleErrorResponse({"add_support_person": "success"}));
        dispatch(fetchSuccess());
      } 
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
      dispatch(handleErrorResponse({"add_support_person": error}));
      dispatch(fetchError(error?error.data?error.data.error:"Error Adding Support Person!":"Error Adding  Support Person!"));
    })
  }
};

export const editSupportPerson = (recordId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true,
closeModelOnResponse:()=>{} ,pageListToFetchOnResponce:1 ,currentFilters:{}, user:[] ,pageSize: defaultPageListLimit}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  const filters = filterBy && filterBy.currentFilters
  dispatch(fetchStart());
  const apiEndpoint = EDIT_SUPPORT_PERSON.replace('id', recordId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(typeof filterBy.closeModelOnResponse === 'function'){
          filterBy.closeModelOnResponse();
        }
        (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && 
              dispatch(fetchSupportPersonsList({pagination: true ,page: filterBy.pageListToFetchOnResponce, 
                supply_source: filters.supply_source ,name: filters.name ,rate: filters.rate , technology: filters.technology, 
                frequency_of_payment: filters.frequency_of_payment, currency: filters.currency,
                experience: filters.experience
                ,...( filterBy.user && filterBy.user.groups.includes("Admin") && {page_size: filterBy.pageSize})
              })) 

        dispatch(handleErrorResponse({"add_support_person": "success"}));
        dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
        dispatch(showMessage("Support person edited successfully!"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      // handleErrorsInDifferentKey(error)
      dispatch(handleErrorResponse({"add_support_person": error}));
      dispatch(handleRemoveDisableSubmitButton('add_support_person_form'))
    });
  };
};

export const fetchSupportDetailsSuccess = (response) => {
  return {
    type: FETCH_GET_SUPPORT_DETAILS_DATA,
    payload: response
  }
};

export const fetchSupportDetailsData = (filterBy={start_date: null ,end_date: null, support: null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_SUPPORT_DETAILS_DATA, filterBy).then((response) => {
        if (response) {
            dispatch(fetchSupportDetailsSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
        dispatch(fetchProjectCoordinatorsOffersListFailure())
      });
  };
};

export const fetchSupportInvoiceTransactionSuccess = (response) => {
  return {
    type: FETCH_GET_SUPPORT_INVOICE_TRANSACTION,
    payload: response
  }
};

export const fetchSupportInvoiceTransactionList = (filterBy={invoice_number:null, page: 1}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_SUPPORT_INVOICE_TRANSACTION.replace('invoice_number', filterBy.invoice_number);
    delete filterBy.invoice_number
    userService.get(apiEndpoint,filterBy).then((response) => {
      if (response) {
          dispatch(fetchSupportInvoiceTransactionSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
        dispatch(fetchError(error.data.error));
    });
  };
};

export const editSupportInvoiceTransaction = (filterBy={transactions_id: null,value: null, 
  updateTableCellOnResponse:(()=>{})
}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_SUPPORT_INVOICE_TRANSACTION.replace('transactions_id', filterBy.transactions_id);
    delete filterBy.transactions_id
    userService.patch(apiEndpoint, filterBy.value).then((response) => {
      if (response) {
        if(filterBy.updateTableCellOnResponse){
          filterBy.updateTableCellOnResponse()
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
        dispatch(fetchError(error.data.error));
    });
  };
};

export const createInvoice = (filterBy={values: null}) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(GET_SUPPORT_INVOICE_LIST, filterBy.values).then((response) => {
      if(response) {
        dispatch(handleRemoveDisableSubmitButton('create_invoice'))
        dispatch(handleErrorResponse({"create_invoice": "success"}));
        dispatch(fetchSuccess());
        message.success('Successful!')
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      if(error && error.data && error.data.errors && error.data.errors.non_field_errors && error.data.errors.non_field_errors[0]){
        message.error(error.data.errors.non_field_errors[0])
      }
      dispatch(handleRemoveDisableSubmitButton('create_invoice'))
      dispatch(fetchError('Problem creating invoice !'));
      dispatch(handleErrorResponse({"create_invoice": error}));
    })
  }
};

export const uploadInvoiceAttachment = (filterBy={invoice_id: null, formData: null, updateTableCellOnResponse:(()=>{}), 
  closeModal:true, alternate_email: null
}) =>{
  const headers = { 'Content-Type': 'multipart/form-data' }
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = UPLOAD_INVOICE_ATTACHMENT.replace('invoiceId', filterBy.invoice_id );
    userService.post(apiEndpoint, filterBy.formData, headers).then((response) => {
      if(response) {
        if (filterBy.alternate_email) {
          dispatch(sendSupportApproveInvoiceMail({
            invoice_id: filterBy.invoice_id, 
            alternate_email: filterBy.alternate_email
          }));
        }
        dispatch(fetchSuccess());
        if(filterBy.updateTableCellOnResponse){
          filterBy.updateTableCellOnResponse(response)
        }
        if(filterBy.closeModal){
          dispatch(handleRemoveDisableSubmitButton('edit_invoice_form'))
          dispatch(handleErrorResponse({"edit_invoice_form": "success"}));
        }
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data?.error));
      message.error('Problem uploading attachment !');
      if(filterBy.closeModal){
        dispatch(handleRemoveDisableSubmitButton('edit_invoice_form'))
        dispatch(handleErrorResponse({"edit_invoice_form": error}));
      }
    })
  }
};

export const editInvoiceIsPaidStatus = (filterBy={invoiceId: null, is_paid: null}, 
  filterBy_Page={currentPage: 1, fetchListOnResponce: true, updateTableCellOnResponse:(()=>{}), currentRecord:null,
  currentFilters:{},
}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = UPDATE_INVOICE_ISPAID_STATUS.replace('invoiceId', filterBy.invoiceId);
    userService.patch(apiEndpoint, filterBy).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(filterBy_Page.updateTableCellOnResponse && filterBy_Page.currentRecord){
          filterBy_Page.updateTableCellOnResponse(filterBy, filterBy_Page.currentRecord, 'is_paid')
        }
        // if(filterBy_Page.fetchListOnResponce){ // call this if wwant to call the list api
        //   dispatch(fetchSupportInvoiceList({page: filterBy_Page.currentPage, ...filterBy_Page.currentFilters,
        //     ...(filterBy.pageSize && filterBy.pageSize !== null && filterBy.pageSize !== undefined && {pageSize: filterBy.pageSize})
        //   }))
        // }
        // dispatch(handleRemoveDisableSubmitButton('edit_invoice_form'))
        // dispatch(handleErrorResponse({"edit_invoice_form": "success"}));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleRemoveDisableSubmitButton('edit_invoice_form'))
      dispatch(handleErrorResponse({"edit_invoice_form": error}));
    });
  };
};

export const editInvoice = (filterBy={invoiceId:null, offer: null, offer_support_id: null, payee_id: null,
  start_date: null, end_date: null, hours: null, rate: null, total_paid: null, extra_amount: null}, 
  filterBy_Page={currentPage: 1, fetchListOnResponce: true, updateTableCellOnResponse:(()=>{}), currentRecord:null,
  currentFilters:{}, }) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_SUPPORT_INVOICE.replace('invoiceId', filterBy.invoiceId);
    delete filterBy.total_paid
    userService.patch(apiEndpoint, filterBy).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        message.success("Edited Successfully")
        if(filterBy_Page.updateTableCellOnResponse && filterBy_Page.currentRecord){
          filterBy_Page.updateTableCellOnResponse(filterBy, filterBy_Page.currentRecord, 'fullUpdated')
        }
        if(filterBy_Page.fetchListOnResponce){
          dispatch(fetchSupportInvoiceList({page: filterBy_Page.currentPage, ...filterBy_Page.currentFilters,
            ...(filterBy.pageSize && filterBy.pageSize !== null && filterBy.pageSize !== undefined && {pageSize: filterBy.pageSize})
          }))
        }
        dispatch(handleRemoveDisableSubmitButton('edit_invoice_form'))
        dispatch(handleErrorResponse({"edit_invoice_form": "success"}));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleRemoveDisableSubmitButton('edit_invoice_form'))
      dispatch(handleErrorResponse({"edit_invoice_form": error}));
    });
  };
};

export const deleteSupportInvoice = (filterBy={invoiceId:null, currentPage:1, pageSize:null,
  updateTableCellOnResponse:(()=>{}), fetchListOnResponce: true, record:null
}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_SUPPORT_INVOICE.replace('invoiceId', filterBy.invoiceId);
    userService.destroy(apiEndpoint).then((response) => {
      if (response === '') {
        dispatch(fetchSuccess());
        if(filterBy.fetchListOnResponce === false && filterBy.record){
          if(filterBy.updateTableCellOnResponse){
            filterBy.updateTableCellOnResponse(null, filterBy.record, 'delete')
          }
        }else{
          dispatch(fetchSupportInvoiceList({page: filterBy.currentPage,
            ...(filterBy.pageSize && filterBy.pageSize !== null && filterBy.pageSize !== undefined && {pageSize: filterBy.pageSize})
          }))
        }
        message.success('Deleted Successfully!');
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  };
}

export const changeInvoiceStatus = (filterBy={invoiceId: null, currentPage: 1, status:null, pageSize:null,
  fetchListOnResponce:true
}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = DELETE_SUPPORT_INVOICE.replace('invoiceId', filterBy.invoiceId);
    userService.patch(apiEndpoint, filterBy.status).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        message.success("Updated Successfully")
        dispatch(handleErrorResponse({"edit_invoice_status": "success"}))
        if(filterBy.fetchListOnResponce){
          dispatch(fetchSupportInvoiceList({page: filterBy.currentPage,
            ...(filterBy.pageSize && filterBy.pageSize !== null && filterBy.pageSize !== undefined && {pageSize: filterBy.pageSize})
          }))
        }
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchError('Problem updating invoice status !'));
      dispatch(handleErrorResponse({"edit_invoice_status": error}));
      if(filterBy.fetchListOnResponce){
        dispatch(fetchSupportInvoiceList({page: filterBy.currentPage,
          ...(filterBy.pageSize && filterBy.pageSize !== null && filterBy.pageSize !== undefined && {pageSize: filterBy.pageSize})
        }))
      }
    });
  };
};

export const fetchSupportInvoiceDetailListSucess = (response) => {
  return {
    type: FETCH_SUPPORT_INVOICE_DETAILS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchInvoiceDetailsList = (filterBy={invoice_number:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_SUPPORT_INVOICE_DETAILS_LIST.replace('invoice_number', filterBy.invoice_number);
    userService.get(apiEndpoint, filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          dispatch(fetchSupportInvoiceDetailListSucess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
    });
  };
};

export const sendApproveInvoiceMail = (filterBy={invoice_id: null, alternate_email: null}) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = SEND_APPROVE_INVOICE_ATTACHMENT.replace('invoiceId', filterBy?.invoice_id );
    if (filterBy?.alternate_email) {
      apiEndpoint.concat(`?alternate_email=${filterBy.alternate_email}`);
      console.log('apiEndpoint apiEndpoint',apiEndpoint)
    }
    userService.post(apiEndpoint).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
        message.success('Mail sent successfully!');
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      message.error('Problem sending mail !');
      if(error && error.data && error.data.detail){
        message.error(error.data.detail);
      }
    })
  }
};

export const sendSupportApproveInvoiceMail = (filterBy={invoice_id: null, alternate_email: null}) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = SEND_APPROVE_SUPPORT_INVOICE_ATTACHMENT.replace('invoiceId', filterBy?.invoice_id );
    if (filterBy.alternate_email) {
      apiEndpoint.concat(`?alternate_email=${filterBy.alternate_email}`);
    }
    userService.post(apiEndpoint).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
        message.success('Mail sent successfully!');
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      message.error('Problem sending mail !');
      if(error && error.data && error.data.detail){
        message.error(error.data.detail);
      }
    })
  }
};

export const fetchProjectCoordinatorsListSuccess = (response) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchProjectCoordinatorsNoPaginationListSuccess = (response) => {
  return {
    type: FETCH_PROJECT_COORDINATORS_NO_PAGINATION_LIST_SUCCESS,
    payload: response
  }
};

export const fetchProjectCoordinatorsList = (filter_By={ all:false}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_PROJECT_COORDINATORS_LIST,filter_By).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          if(filter_By && filter_By.all === true){
            dispatch(fetchProjectCoordinatorsNoPaginationListSuccess(response))
          }else{
            dispatch(fetchProjectCoordinatorsListSuccess(response))
          }
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
     });
  };
};

// engineer 
export const fetchEngineeJoiningPendingOffersListSuccess = (offers) => {
  return {
    type: FETCH_ENGINEER_JOINING_PENDING_OFFER_SUCCESS,
    payload: offers
  }
};
export const fetchEngineeOffersListSuccess = (offers) => {
  return {
    type: FETCH_ENGINEER_OFFER_LIST_SUCCESS,
    payload: offers
  }
};


export const fetchEngineeOffersListFailure = () => {
  return {
    type: FETCH_ENGINEER_OFFER_FAILED,
  }
};


export const fetchEngineerOffersList = (filterBy={page:1 ,marketer:null ,project_start_date: null ,project_end_date: null,
  team_profiles:null ,from_date: null ,to_date: null  ,q:null ,page_size:null , project_status:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ENGINEER_OFFERS, filterBy).then((response) => {
        if (response) {
          if(filterBy.project_status==='j'){
            dispatch(fetchEngineeJoiningPendingOffersListSuccess(response));
          }
          else{
            dispatch(fetchEngineeOffersListSuccess(response))
          }
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
          dispatch(fetchEngineeOffersListFailure())
      });
  };
};

export const editEngineerOffers = (offerId=null, values=null ,filterBy={updateTableCellOnResponse:()=>{},fetchListOnResponce:true, 
    status:null ,closeModelOnResponce:()=>{}, currentPageToFetchOnResponce:null, jpCurrentPageToFetchOnResponce:1,
    ongoingCurrentPageToFetchOnResponce: 1
  }) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = Edit_ENGINEER_OFFERS.replace('id', offerId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        // filterBy.closeModelOnResponce() // this the function to close the model from which he api is called
        // filterBy.updateTableCellOnResponse() // this will be used when editing the table data from the table
        if(filterBy.status==='j'){
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchEngineerOffersList({project_status:'j', page: filterBy.jpCurrentPageToFetchOnResponce}))
          if(values !== null && values.get('project_status') === 'o'){
            dispatch(fetchEngineerOffersList({project_status:'o', page: filterBy.ongoingCurrentPageToFetchOnResponce}))
          } 
        }
        else{
          (filterBy.fetchListOnResponce === undefined || filterBy.fetchListOnResponce === true) && dispatch(fetchEngineerOffersList({project_status:'o', page: filterBy.ongoingCurrentPageToFetchOnResponce})) 
          dispatch(fetchEngineerOffersList({project_status:'j', page: filterBy.jpCurrentPageToFetchOnResponce}))
        }
        dispatch(handleRemoveDisableSubmitButton('edit_engineer_offers_form'))
        dispatch(showMessage("Offer Edited Successfully"))
        dispatch(handleErrorResponse({"edit_engineer_offers_form": "success"}));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleErrorResponse({"edit_engineer_offers_form": error}));
      // handleErrorsInDifferentKey(error)
      dispatch(handleRemoveDisableSubmitButton('edit_engineer_offers_form'))
    });
  };
};

export const fetchTimeSheetConfigListSuccess = (response) => {
  return {
    type: FETCH_TIMESHEET_CONFIG_LIST_SUCCESS,
    payload: response
  }
};

export const fetchResetTimeSheetConfigList = (response) => {
  return {
    type: FETCH_TIMESHEET_CONFIG_LIST_SUCCESS,
    payload: {
      "count": 0,
      "next": null,
      "previous": null,
      "results": [],
      "timesheet_email": null
    }
  }
};

export const fetchTimeSheetConfigList = (filterBy={offerId:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_TIMESHEET_CONFIG_LIST.concat(filterBy.offerId);
    userService.get(apiEndpoint).then((response) => {
        if (response) {
            dispatch(fetchTimeSheetConfigListSuccess(response))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const createTimeSheetConfig = (values, filterBy={offerId:null}) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(CREATE_TIMESHEET_CONFIG, values).then((response) => {
      if(response) {
        dispatch(handleRemoveDisableSubmitButton('timesheet_config_form'))
        dispatch(handleErrorResponse({"timesheet_config_form": "success"}));
        if(filterBy.offerId && filterBy.monthStartDateInTheCalendar && filterBy.monthEndDateInTheCalendar){
          dispatch(fetchTimeSheetConfigList({offerId: filterBy.offerId}))
          dispatch(fetchTimeSheetHoursList({offer: filterBy.offerId, from_date: filterBy.monthStartDateInTheCalendar,
            to_date: filterBy.monthEndDateInTheCalendar}))
          dispatch(fetchTimeSheetAttachmentList({offer: filterBy.offerId, from_date: filterBy.monthStartDateInTheCalendar,
            to_date: filterBy.monthEndDateInTheCalendar, attachment_type: 'client'}))
          dispatch(fetchTimeSheetAttachmentList({offer: filterBy.offerId, from_date: filterBy.monthStartDateInTheCalendar,
            to_date: filterBy.monthEndDateInTheCalendar, attachment_type: 'vendor'}))
          dispatch(fetchTimeSheetAttachmentList({offer: filterBy.offerId, from_date: filterBy.monthStartDateInTheCalendar,
            to_date: filterBy.monthEndDateInTheCalendar, attachment_type: 'pay_stub'}))
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('timesheet_config_form'))
      dispatch(handleErrorResponse({"timesheet_config_form": error}));
      dispatch(fetchError(error?error.data?error.data.error:"Error while updating config!":"Error while updating config!"));
      dispatch(addOfferFailed());
    })
  }
};

export const fetchTimeSheetHoursListSuccess = (response) => {
  return {
    type: FETCH_TIMESHEET_HOURS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchTimeSheetHoursList = (filterBy={offer:null, from_date:null, to_date:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_TIMESHEET_HOURS_LIST,filterBy).then((response) => {
        if (response) {
            dispatch(fetchTimeSheetHoursListSuccess(response))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const addTimeSheetHours = (filterBy={value:null}, updateTableCellOnResponse) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_TIMESHEET_HOURS, filterBy.value).then((response) => {
      if(response) {
        updateTableCellOnResponse()
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error?error.data?error.data.error:"Error while adding hours!":"Error while adding hours!"));
      dispatch(addOfferFailed());
    })
  }
};

export const editTimeSheetHours = (filterBy={value:null}, updateTableCellOnResponse) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(EDIT_TIMESHEET_HOURS, filterBy.value).then((response) => {
      if(response) {
        if(response && response.hours){
          updateTableCellOnResponse(response.hours)
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      if(error && error.data && error.data.errors && error.data.errors.non_field_errors && typeof error.data.errors.non_field_errors === 'object'){
        if(error.data.errors.non_field_errors && error.data.errors.non_field_errors[0]){
          message.error(error.data.errors.non_field_errors[0])
        }
      }
      if(error && error.data && error.data.errors && error.data.errors.work_date){
        if(error.data.errors.work_date && error.data.errors.work_date[0]){
          message.error(error.data.errors.work_date[0])
        }
      }
      dispatch(fetchError(error?error.data?error.data.error:"Error while updating hours!":"Error while updating hours!"));
      dispatch(addOfferFailed());
    })
  }
};

export const addTimeSheetAttachment = (filterBy={formData:null}, monthStartDateInTheCalendar, monthEndDateInTheCalendar,
  otherFilter={showHoursOnlyInDateRangeForModal:false, endDateRange: null, startDateRange: null}
) =>{
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_TIMESHEET_ATTACHMENT, filterBy.formData, headers).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
        dispatch(handleRemoveDisableSubmitButton('upload_timeSheet_attachment_form'))
        if(filterBy && filterBy.formData && filterBy.formData.get('attachment_type') && filterBy.formData.get('offer')){
          if(otherFilter.showHoursOnlyInDateRangeForModal){ // this is to show only the data according to a specific date range not for full month (for Timesheet in Modal)
            dispatch(fetchTimeSheetAttachmentList({offer: filterBy.formData.get('offer'),
              hours_from_date: otherFilter.showHoursOnlyInDateRangeForModal ? otherFilter.startDateRange : monthStartDateInTheCalendar, 
              hours_to_date: otherFilter.showHoursOnlyInDateRangeForModal ? otherFilter.endDateRange : monthEndDateInTheCalendar, 
              attachment_type: filterBy.formData.get('attachment_type')
            }))
          }else{
            dispatch(fetchTimeSheetAttachmentList({offer: filterBy.formData.get('offer'), 
              from_date: monthStartDateInTheCalendar, 
              to_date: monthEndDateInTheCalendar, 
              attachment_type: filterBy.formData.get('attachment_type')
            }))
          }
        }
        dispatch(handleErrorResponse({"upload_timeSheet_attachment_form": "success"}))
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('upload_timeSheet_attachment_form'))
      dispatch(handleErrorResponse({"upload_timeSheet_attachment_form": error}))
      dispatch(fetchError(error?error.data?error.data.error:"Error while adding attachment!":"Error while adding attachment!"));
      dispatch(addOfferFailed());
    })
  }
};

export const EditTimeSheetAttachments = (filterBy={formData:null}, otherFilter={attachment_Id: null, 
    monthStartDateInTheCalendar: null, monthEndDateInTheCalendar: null, attachment_type:null, offerId: null,
    updateTableCellOnResponce:(()=>{}), fetchLimitedDateAttachment: false
  }) =>{
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_TIMESHEET_ATTACHMENT.replace('attachment_Id',otherFilter.attachment_Id)
    userService.patch(apiEndpoint, filterBy.formData, headers).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
        dispatch(handleRemoveDisableSubmitButton('edit_timeSheet_attachment_form'))
        dispatch(handleErrorResponse({"edit_timeSheet_attachment_form": "success"}))
        if(otherFilter && otherFilter.offerId && otherFilter.attachment_type && 
          otherFilter.fetchLimitedDateAttachment // this is to fetch the attachemnt of the given date only not for full month
        ){
          dispatch(fetchTimeSheetAttachmentList({offer: otherFilter.offerId, 
            hours_from_date: otherFilter.hours_from_date, 
            hours_to_date: otherFilter.hours_to_date, 
            attachment_type: otherFilter.attachment_type
          }))
        }
        if(otherFilter && otherFilter.monthStartDateInTheCalendar && otherFilter.monthEndDateInTheCalendar
          && otherFilter.offerId && otherFilter.attachment_type && !otherFilter.fetchLimitedDateAttachment
        ){
          dispatch(fetchTimeSheetAttachmentList({offer: otherFilter.offerId, 
            from_date: otherFilter.monthStartDateInTheCalendar, 
            to_date: otherFilter.monthEndDateInTheCalendar, 
            attachment_type: otherFilter.attachment_type
          }))
        }
        if(typeof otherFilter.updateTableCellOnResponce === 'function'){
          otherFilter.updateTableCellOnResponce(response)
        }
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('edit_timeSheet_attachment_form'))
      dispatch(handleErrorResponse({"edit_timeSheet_attachment_form": error}))
      dispatch(fetchError(error?error.data?error.data.error:"Error while updating attachment!":"Error while updating attachment!"));
      dispatch(addOfferFailed());
    })
  }
};

export const updateTimeSheetAttachmentStatus = (filterBy={status: null, rejection_note: null, attachment_type:null, attachmentId:null,
    from_date:null, to_date:null, offer:null, fatchListOnResponce: true, updateTableCellOnResponce:(()=>{}),
    updateTableCell:false, record:null
  }) =>{
  return (dispatch) => {
    dispatch(fetchStart());
    let attached_id = filterBy.attachmentId
    const endPoint = UPDATE_TIMESHEET_ATTACHMENT_STATUS.replace('attachmentId',filterBy.attachmentId)
    delete filterBy.attachmentId
    const { fatchListOnResponce, ...otherFilters } = filterBy;
    userService.patch(endPoint, otherFilters).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
        if(filterBy.updateTableCell && typeof filterBy.updateTableCellOnResponce === 'function' && attached_id && filterBy.record){
          filterBy.updateTableCellOnResponce(response, attached_id, filterBy.record);
        }
        if(filterBy && filterBy.from_date && filterBy.to_date && filterBy.offer && filterBy.fatchListOnResponce){
          dispatch(fetchTimeSheetHoursList({offer: filterBy.offer, from_date:filterBy.from_date, 
            to_date:filterBy.to_date }))
          dispatch(fetchTimeSheetAttachmentList({offer: filterBy.offer, from_date:filterBy.from_date, 
            to_date:filterBy.to_date, attachment_type: filterBy.attachment_type  }))
        }else if(filterBy && filterBy.hours_from_date && filterBy.hours_to_date && filterBy.offer && filterBy.fatchListOnResponce){
          dispatch(fetchTimeSheetHoursList({offer: filterBy.offer, hours_from_date:filterBy.hours_from_date, 
            hours_to_date:filterBy.hours_to_date }))
          dispatch(fetchTimeSheetAttachmentList({offer: filterBy.offer, hours_from_date:filterBy.hours_from_date, 
            hours_to_date:filterBy.hours_to_date, attachment_type: filterBy.attachment_type  }))
          // dispatch(fetchTimeSheetHoursList({offer: filterBy.offer, from_date:filterBy.hours_from_date, // we use this code preiously to fetch the updated hourse and attachment after action from the dashboard timesheet modal
          //   to_date:filterBy.hours_to_date }))
          // dispatch(fetchTimeSheetAttachmentList({offer: filterBy.offer, from_date:filterBy.hours_from_date, 
          //   to_date:filterBy.hours_to_date, attachment_type: filterBy.attachment_type  }))
        }else{
          if(typeof filterBy.updateTableCellOnResponce === 'function'){
            filterBy.updateTableCellOnResponce(response)
          }
        }
      }
    }).catch(function (error) {
      handleErrorsInDifferentKey(error)
      dispatch(fetchError(error?error.data?error.data.error:"Error while updating attachment status!":"Error while updating attachment status!"));
    })
  }
};

export const sendTimeSheetAttachmentReminder = (filterBy={alternate_email:null, offerId:null, 
  cycle_start:null, cycle_end:null, date_ranges: null, updateTableCellOnResponce:(()=>{})
}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const endPoint = SEND_TIMESHEET_ATTACHMENT_REMINDER.replace('offerId', filterBy.offerId);
    delete filterBy.offerId
    userService.post(endPoint,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess())
          dispatch(showMessage("Send Successfully !"))
          if(filterBy.updateTableCellOnResponce){
            filterBy.updateTableCellOnResponce()
          }
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchTimeSheetAttachmentListSuccess = (response) => {
  return {
    type: FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    payload: response
  }
};

export const fetchVendorTimeSheetAttachmentListSuccess = (response) => {
  return {
    type: FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    payload: response
  }
};

export const fetchPayStubTimeSheetAttachmentListSuccess = (response) => {
  return {
    type: FETCH_PAY_STUB_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    payload: response
  }
};

export const resetBothClientVendorTimesheetAttachment = () => {
  return (dispatch) => {
    dispatch({
      type: FETCH_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
      payload: {
        "count": 0,
        "results": []
      }
    });
    dispatch({
      type: FETCH_VENDOR_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
      payload: {
        "count": 0,
        "results": []
      }
    });
    dispatch({
      type: FETCH_PAY_STUB_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
      payload: {
        "count": 0,
        "results": []
      }
    });
  };
};

export const fetchTimeSheetAttachmentList = (filterBy={offer:null, from_date:null, to_date:null, attachment_type:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_TIMESHEET_ATTACHMENT_LIST,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
          if(filterBy.attachment_type === 'pay_stub'){
            dispatch(fetchPayStubTimeSheetAttachmentListSuccess(response))
          }
          else if(filterBy.attachment_type === 'vendor'){
            dispatch(fetchVendorTimeSheetAttachmentListSuccess(response))
          }else{
            dispatch(fetchTimeSheetAttachmentListSuccess(response))
          }
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const removePendingTimeSheetAttachmentObjfromList = (attachementRecord) => {
  return {
    type: REMOVE_PENDING_TIMESHEET_ATTACHMENT_OBJ__FROM_LIST,
    payload: attachementRecord
  }
};

export const fetchPendingTimeSheetAttachmentListSuccess = (response) => {
  return {
    type: FETCH_PENDING_TIMESHEET_ATTACHMENT_LIST_SUCCESS,
    payload: response
  }
};

export const fetchPendingTimeSheetAttachmentList = (filterBy={offer:null, from_date:null, to_date:null, attachment_type:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    filterBy['status'] = 'pending'
    userService.get(GET_TIMESHEET_ATTACHMENT_LIST,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
            dispatch(fetchPendingTimeSheetAttachmentListSuccess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const fetchCurrentYearClosedOfferListSuccess = (response) => {
  return {
    type: FETCH_CURRENT_YEAR_CLOSED_OFFER_LIST_SUCCESS,
    payload: response
  }
};

export const fetchCurrentYearClosedOfferList = (filterBy={year:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_CURRENT_YEAR_CLOSED_OFFER,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
            dispatch(fetchCurrentYearClosedOfferListSuccess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

// Contract Team

export const fetchContractTeamJoiningPendingOffersListSuccess = (offers) => {
  return {
    type: FETCH_CONTRACT_TEAM_OFFER_LIST_SUCCESS,
    payload: offers
  }
};

export const fetchContractIncompleteVerificationListSuccess = (offers) => {
  return {
    type: FETCH_CONTRACT_INCOMPLETE_VERIFICATION_LIST_SUCCESS,
    payload: offers
  }
};

export const fetchContractVerififedListSuccess = (offers) => {
  return {
    type: FETCH_CONTRACT_VERIFIED_LIST_SUCCESS,
    payload: offers
  }
};

export const fetchContractTeamOffersList = (filterBy={page:1, status:null, exclude:null, verified:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_CONTRACT_TEAM_OFFERS, filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
            if(filterBy.status === 'j'){
              dispatch(fetchContractTeamJoiningPendingOffersListSuccess(response))
            }
            if(filterBy.verified === false){
              dispatch(fetchContractIncompleteVerificationListSuccess(response))
            }
            if(filterBy.verified === true){
              dispatch(fetchContractVerififedListSuccess(response))
            }
          }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const editContractTeamOffers = (offerId=null, values=null ,filterBy={currentPageToFetchOnResponce:{}, selectedTableType:null, record:null,
  verifiedTablePage: null
}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  const apiEndpoint = Edit_CONTRACT_TEAM_OFFERS.replace('offerId', offerId);
  userService.patch(apiEndpoint, values,{
    'Content-Type': 'multipart/form-data',
    }).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(filterBy && filterBy.currentPageToFetchOnResponce && typeof(filterBy.currentPageToFetchOnResponce)==='number'){
          if(filterBy.selectedTableType === 'j'){
            dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce, status: 'j'}))
          }else{
            dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce, verified: false}))
            dispatch(fetchContractTeamOffersList({page: (filterBy.verifiedTablePage||1), verified: true}))
          }
        }
        if(filterBy && filterBy.currentPageToFetchOnResponce && typeof(filterBy.currentPageToFetchOnResponce)==='object'){
          if(filterBy.selectedTableType === 'j' && values && values.get('project_status') && (values.get('project_status') === 'j')){
            dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.joiningPendingCurrentPage, status: 'j'}))
          }else{
            if(filterBy.selectedTableType === 'j' && values && values.get('project_status') && values.get('project_status') !== 'j'){
              dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.joiningPendingCurrentPage, status: 'j'}))
              if(filterBy && filterBy.record && filterBy.record.background_check && filterBy.record.paperwork && (filterBy.record.paperwork==='complete' && filterBy.record.background_check==='complete')){
                dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.verifiedTablePage, verified: true}))
              }
              else{
                dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.incompleteVerificationPage, verified: false}))
              }
            }
              if(filterBy.selectedTableType !== 'j' && values && values.get('project_status') && values.get('project_status') === 'j'){
                dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.joiningPendingCurrentPage, status: 'j'}))
                if(filterBy && filterBy.record && filterBy.record.background_check && filterBy.record.paperwork && (filterBy.record.paperwork==='complete' && filterBy.record.background_check==='complete' )){
                  dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.verifiedTablePage, verified: true}))
                }
                else{
                  dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.incompleteVerificationPage, verified: false}))
                }
              }else{
                dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.incompleteVerificationPage, verified: false}))
                dispatch(fetchContractTeamOffersList({page: filterBy.currentPageToFetchOnResponce.verifiedTablePage, verified: true}))
              }
          }
        }
        dispatch(handleRemoveDisableSubmitButton('edit_contract_team_offers_form'))
        dispatch(showMessage("Offer Edited Successfully"))
        dispatch(handleErrorResponse({"edit_contract_team_offers_form": "success"}));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      handleErrorsInDifferentKey(error)
      dispatch(handleErrorResponse({"edit_contract_team_offers_form": error}));
      dispatch(handleRemoveDisableSubmitButton('edit_contract_team_offers_form'))
    });
  };
}

export const fetchContractTeamDocumentListSuccess = (offers) => {
  return {
    type: FETCH_CONTRACT_TEAM_DOCUMENT_LIST_SUCCESS,
    payload: offers
  }
};

export const fetchContractTeamDocumentList = (offerId, filterBy={ category:null, status:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_CONTRACT_TEAM_DOCUMENT_LIST.replace('offerId', offerId); 
    userService.get(apiEndpoint, filterBy).then((response) => {
        if (response) {
          dispatch(fetchSuccess());
            dispatch(fetchContractTeamDocumentListSuccess(response))
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};


export const uploadContractTeamAttachment = (offerId, filterBy={formData:null}) =>{
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = CONTRACT_TEAM_ATTACHMENT.replace('offerId', offerId); 
    userService.post(apiEndpoint, filterBy.formData, headers).then((response) => {
      if(response) {
        dispatch(fetchSuccess());
      dispatch(showMessage("Successful!"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error?error.data?error.data.error:"Error while adding attachment!":"Error while adding attachment!"));
    })
  }
};

export const approveContractTeamAttachments = (offerId=null, documentId=null ,filterBy={status:null, category:null}) => {  // fetchListOnResponce this is passed to call the list again after the response or not 
  return(dispatch) => {
  dispatch(fetchStart());
  var apiEndpoint = APPROVE_CONTRACT_TEAM_ATTACHMENT.replace('offerId', offerId).replace('documentId', documentId);

  userService.patch(apiEndpoint, filterBy.status).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(filterBy && filterBy.category){
          dispatch(fetchContractTeamDocumentList(offerId,{category: filterBy.category}))
        }
        dispatch(showMessage("Successful!"))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  };
}


// consultant

export const fetchConsultantOffersListSuccess = (offers) => {
  return {
    type: FETCH_CONSULTANT_OFFER_LIST_SUCCESS,
    payload: offers
  }
};

export const fetchConsultantOffersList = (filterBy={page:1 }) => {  // this will fetch the ongoing offers of the consultant
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_CONSULTANT_OFFERS, filterBy).then((response) => {
        if (response) {
            dispatch(fetchConsultantOffersListSuccess(response))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const assignePayeeOnOffer = (filterBy={offerId:null ,values:null, updateTableCellOnResponse:()=>{}}) => {  // this will fetch the ongoing offers of the consultant
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = ASSIGN_PAYEE_ON_OFFERS.replace('offerId', filterBy.offerId); 
    userService.patch(apiEndpoint, filterBy.values).then((response) => {
        if (response) {
          dispatch(showMessage("Successful!"))
          if(filterBy.values && filterBy.values.remove_payee && filterBy.values.remove_payee.length > 0){
            filterBy.updateTableCellOnResponse()
          }
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const assigneAccountOfferPayee = (filterBy={offerId:null ,values:null}) => {
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = ASSIGN_CONSULTANT_PAYEE_ON_OFFERS.replace('offerId', filterBy.offerId); 
    userService.patch(apiEndpoint, filterBy.values).then((response) => {
        if (response) {
          dispatch(showMessage("Successful!"))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};

export const editProjectCoordinatorPayee = (filterBy={offerId:null, payeeId:null ,values:null, updateTableCellOnResponse:()=>{}}) => { 
  return(dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = Edit_CONSULTANT_PAYEE_ON_OFFERS.replace('offerId', filterBy.offerId).replace('payeeId', filterBy.payeeId); 
    userService.patch(apiEndpoint, filterBy.values).then((response) => {
        if (response) {
          dispatch(showMessage("Successful!"))
          filterBy.updateTableCellOnResponse()
          dispatch(fetchSuccess());
          dispatch(handleRemoveDisableSubmitButton('edit_payee_form'))
        }
      }).catch(function (error) {
          dispatch(handleRemoveDisableSubmitButton('edit_payee_form'))
          dispatch(fetchError(error.data.error));
      });
  };
};

// engineer

export const fetchSingleEngineeOffersListSuccess = (offers) => {
  return {
    type: FETCH_SINGLE_ENGINEER_OFFER_SUCCESS,
    payload: offers
  }
};

export const fetchSingleEngineerOffersList = (filterBy={page:1, inhouse_engineer:null}) => { 
  return(dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_ENGINEER_OFFERS,filterBy).then((response) => {
        if (response) {
          dispatch(fetchSingleEngineeOffersListSuccess(response))
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
          dispatch(fetchError(error.data.error));
      });
  };
};