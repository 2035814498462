import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';

const ConfettiPage = () => {
  useEffect(() => {
    const shootConfetti = () => {
      confetti({
        particleCount: 300,  // Adjust for density
        spread: 190,  // Spread outwards
        startVelocity: 20,  // Falling speed
        angle: 90,  // Directly downward
        origin: { x: Math.random(), y: Math.random() }, // Random horizontal positions, starting from the top
        gravity: 1,  // Slower falling effect
        ticks: 450,
      });
    };

    // Fire confetti repeatedly for a raining effect
    const interval = setInterval(shootConfetti, 200);

    setTimeout(() => {
      clearInterval(interval);
    }, 3000);

    // Cleanup on unmount
    return () => clearInterval(interval);
  }, []);

  return null; // No need for a canvas, confetti.js uses the document body
};

export default ConfettiPage;

// import React, { useEffect, useRef } from 'react';
// import confetti from 'canvas-confetti';

// const ConfettiPage = () => {
//   const canvasRef = useRef(null);

//   useEffect(() => {
//     const myCanvas = canvasRef.current;
//     const confettiInstance = confetti.create(myCanvas, { resize: true });

//     // Launch confetti
//     confettiInstance({
//       particleCount: 1500,
//       spread: 1200,
//       origin: { x: 0.5, y: 0.5 }, // Center of the screen
//       startVelocity: 20,
//     });

//     return () => {
//       // Clean up
//       confettiInstance.reset();
//     };
//   }, []);

//   return (
//     <>
//       <canvas
//         ref={canvasRef}
//         style={{
//           border:"1px solid red",
//           position: 'fixed',
//           top: 0,
//           left: 0,
//           width: '100vw',
//           height: '100vh',
//           zIndex: 9327900,
//           pointerEvents: 'none',
//         }}
//       />
//     </>
//   );
// };

// export default ConfettiPage


// import React from 'react'

// import Confetti from 'react-confetti';
// const ConfettiPage = () => {
//   return (
//     <>
//         <Confetti
//           style={{position:"fixed", top:0, left:0, width:"100vw",height:'100vh', zIndex:9327900}}
//           width={window.outerWidth}
//           height={window.outerHeight}
//           recycle={true}
//           numberOfPieces={500}
//           initialVelocityX={5}
//           initialVelocityY={10}
//         />
//     </>
//   )
// }

// export default ConfettiPage